import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <div style={{ padding: "120px 20px" }}>
        <h1>Page not found</h1>
        <p style={{ fonSize: "1.5rem", margin: "20px auto" }}>
          Sorry 😔, we couldn’t find what you were looking for.
          <u>
            <Link to="/">Try our homepage</Link>
          </u>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <title> Page not found </title>;
